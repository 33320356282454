.dropzone-custom {
    padding: 0 !important;
}

.dz-message-custom {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 150px;
    margin: 0 !important;
}